import ieVersion                         from 'ie-version';
import {pageView}                        from './services/tracking';

const shouldRedirectForIE = !!ieVersion.version;

if (shouldRedirectForIE) {
    if (process.env.PREVIEW) {
        pageView('/preview')
        document.location.href = "/preview.html"
    } else {
        pageView('/ie')
        document.location.href = "/ie.html"
    }
}

import _                                 from 'lodash';
import React, { Component }              from 'react';
import ReactDOM from "react-dom";
import { browserHistory } from "react-router";
import { DragDropContext } from "react-beautiful-dnd";
import AppRoutes from "./routes";
import SplashScreen from "./splash-screen";
import PreviewPage from "./preview";
import { indexInitialData, useStubData } from "./drawer-items";
import preloadFilingCabinet from "./preload-filing-cabinet";
import { soundReady, preloadSoundSprite } from "./sound";
import store from "./store";
import { sfxToLoad } from "./drawer-sfx";
import NotSupported from "./not-supported";
import fakeSubscription from "./fake-subscription";
import { isIPad, isIPhone, isMobile } from "./platform";
import { changePlaylistItemsOrder } from "./actions/playlists";
import { updateEntries } from "./actions/entries";
import { loadFreeTracks } from "./actions/tracks";
import {
  hasAuth,
  isPaying,
  reloadPaymentProcess,
  getInitialData,
  setReferralFreePass,
  getDrawerData,
  generateUuid,
  getOrastreamUuid,
  setOrastreamUuid,
  getNewspaperData,
  createJWT,
  setAccessToken,
  getAccessToken,
} from "./services/api";
import { fetchInitialEntries, fetchExclusiveEntries } from "./services/fetch";
import { isFreeUser } from "./user";
import "./ios-hacks";
import style from "../scss/main.scss";
import router from "react-router/lib/Router";
import { pathInResponsiveViews } from "./route-utils";

console.log("NODE_ENV =", process.env.NODE_ENV);
console.log("CMS_ENV =", process.env.CMS_ENV);
console.log("IE VERSION = ", ieVersion.version);
console.log("FAKING SUBSCRIPTION SERVICES =", fakeSubscription);

let history = browserHistory;

let soundInitialized = false;
let soundLoaded = false;
let imagesLoaded = false;
let initialLoadComplete = false;
let isEmbed = false;

//sound manager fails to initialize properly on iOS, but still works.
//so just pretend it has already loaded...
if (isIPad || isIPhone) {
 soundInitialized = true;
 soundLoaded = true;
}

let imagesPromise, soundReadyPromise, soundLoadedPromise, isYouTubeReferral;
const { pathname } = document.location;
const routeName = pathname.split("/")[1];
const forbiddenRoutes = ["times-contrarian"];
const requestDrawer = !forbiddenRoutes?.includes(routeName);


class App extends Component {
  constructor(props) {
    super(props);

    this.getLoadState = this.getLoadState.bind(this);

    this.state = {
      ...this.stateFromStore(store.getState()),
      showingAuth: false,
      underMaintenance: false,
      imagesLoaded: false,
      soundInitialized: false,
      soundLoaded: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  startLoading(pageLocation) {
    let documentReferrer = document.referrer;
    isYouTubeReferral =
      documentReferrer.includes("youtube") || pageLocation.includes("youtube");

    if (ieVersion.version && ieVersion.version < 11) {
      console.log("SKIPPING PRELOADING FOR IE");
      //ie 10 can't handle all this preloading...
      this.setState({
        soundInitialized: true,
        soundLoaded: true,
        imagesLoaded: true,
      });

      imagesPromise =
        soundReadyPromise =
        soundLoadedPromise =
          Promise.resolve(true);
      return;
    }
    if (requestDrawer) {
      imagesPromise = preloadFilingCabinet().then(() => {});
    } else {
      imagesPromise = Promise.resolve(true);
    }
    this.setState({ imagesLoaded: true });

    if (requestDrawer) {
      soundReadyPromise = soundReady().then(() => {
        this.setState({ soundInitialized: true });
      });
      soundLoadedPromise = soundReadyPromise
        .then(() => {
          let toLoad = sfxToLoad().concat([
            ["drawer-open", "/sounds/filecabinet_opening_1sec.mp3"],
          ]);
          return Promise.all(
            toLoad.map((pair) => preloadSoundSprite.apply(null, pair))
          );
        })
        .then(() => {
          this.setState({ soundLoaded: true });
        });
    } else {
      soundReadyPromise = Promise.resolve(true);
      soundLoadedPromise = Promise.resolve(true);
    }
  }
  getLoadState() {
    const elms = [
      "tracksLoaded",
      // 'albumsLoaded',
      "imagesLoaded",
      // 'soundInitialized',
      "soundLoaded",
    ];

    const loaded = _.size(_.pickBy(_.pick(this.state, elms), _.identity));

    return loaded ? Math.floor(100 / _.size(elms)) * (loaded + 1) : -1;
  }
  stateFromStore(state) {
    const isFirstTime = !this.state;
    return {
      tracksLoaded: useStubData ? true : state.tracks.loaded,
      albumsLoaded: useStubData ? true : state.albums.loaded,
      commonValuesLoaded: useStubData ? true : state.commonValues.loaded,
      imagesLoaded: isFirstTime ? imagesLoaded : this.state.imagesLoaded,
      soundInitialized: isFirstTime
        ? soundInitialized
        : this.state.soundInitialized,
      soundLoaded: isFirstTime ? soundLoaded : this.state.soundLoaded,
    };
  }
  isReadyForDrawer(state = this.state) {
    let {
      tracksLoaded,
      albumsLoaded,
      imagesLoaded,
      soundInitialized,
      soundLoaded,
    } = state;
    return (
      soundInitialized &&
      soundLoaded &&
      imagesLoaded &&
      tracksLoaded &&
      albumsLoaded
    );
  }
  appIsLoaded(state = this.state) {
    const { pathname } = document.location;
    const routeName = pathname.split("/")[1];
    const dontWaitForDrawer = [
      "",
      "menu",
      "news",
      "faq",
      "contact",
      "audio-setup",
      "drawer",
      "timeline",
      "album",
      "playlist",
      "movie-night",
      "embed",
      "times-contrarian",
      "welcome",
    ];

    let { commonValuesLoaded } = state;
    return (
      commonValuesLoaded &&
      (dontWaitForDrawer.indexOf(routeName) !== -1 ||
        this.isReadyForDrawer(state))
    );
  }
  componentDidMount() {
    //detect if the route id embed and only load the
    const pageLocation = window.location.href;
    isEmbed = pageLocation.includes("embed");
    console.log(
      "%c Is Embed route ? ",
      "color:red; background:black;",
      isEmbed
    );

    this.setState({
      imagesLoaded: false,
      soundInitialized: false,
      soundLoaded: false,
    });

    this.startLoading(pageLocation);

    setTimeout(() => {
      store.subscribe(this.checkLoad);
      store.subscribe(this.checkAuth);
    }, 100);

    if (isPaying()) {
      // refresh the page in * sec, this time should be enough for subscription to go through
      setTimeout(() => {
        if (isPaying()) reloadPaymentProcess(0, true);
      }, 5000); // 3 sec
    }

    let checkLoad = async () => {
      if (initialLoadComplete) return;
      let state = store.getState();
      let newstate = this.stateFromStore(state);

      if (this.appIsLoaded(newstate) && this.isReadyForDrawer(newstate)) {
        initialLoadComplete = true;
        await indexInitialData(state);
      }
      if (!_.isEqual(this.state, newstate)) {
        this.setState(newstate);
      }
    };

    // Check this probably could be move up to the begining and sourounded by a promise.all
    // i dont get why these need to be defined on a method at an specifi time, or is that just
    // a way to organize things? in a single method
    soundLoadedPromise.then(checkLoad);
    imagesPromise.then(checkLoad);
    store.subscribe(checkLoad); // check this, doesn't seem that necessary to suscribe something to the
    // store, check it get desuscribed

    let status = store.getState().status;

    let checkAuth = () => {
      if (initialLoadComplete) return;
      let newStatus = store.getState().status;
      if (newStatus === status && status === "failed") return;
      status = newStatus;
      console.log("FETCH STATUS = ", status);
      console.log(newStatus);
      if (status !== "failed") {
        checkLoad();
      }
    };
    store.subscribe(checkAuth);

    Promise.all([
      getInitialData(),
      isEmbed ? Promise.resolve() : getNewspaperData(),
    ]).then(([data, newspaperData]) => {
      this.state.underMaintenance = data.appStatus == "true";
      const { commonValues = { freeTracks: {} } } = data;
      let parsedTracks = [];
      if (typeof commonValues.freeTracks === "object") {
        for (let TRACK_ID in commonValues.freeTracks) {
          parsedTracks.push(commonValues.freeTracks[TRACK_ID]);
        }
      }
      loadFreeTracks(parsedTracks);
      updateEntries(data);
      updateEntries(newspaperData);
      if (!getAccessToken()) createJWT().then(setAccessToken);
    });

    if (!isEmbed && requestDrawer) {
      getDrawerData().then((drawerData) => {
        updateEntries(drawerData);
      });
    }

    fetchInitialEntries();

    if (!getOrastreamUuid()) {
      generateUuid().then((token) => {
        if (token.uuid) setOrastreamUuid(token.uuid);
      });
    }
    if (hasAuth() && !isEmbed) {
      fetchExclusiveEntries().then(() => {
        if (isYouTubeReferral && isFreeUser()) setReferralFreePass();
      });
    } else if (!isEmbed) {
      if (isYouTubeReferral) setReferralFreePass();
    }
  }
  onDragEnd(result) {
    if (result.destination) {
      store.dispatch(changePlaylistItemsOrder(result));
    }
  }
  render() {
    console.log("App Loaded:", this.appIsLoaded());
    console.log("State:", this.state);
    if (this.state.underMaintenance) {
      return <div className="underMaintenance" />;
    }
    if (this.appIsLoaded()) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <AppRoutes store={store} history={history} />
        </DragDropContext>
      );
    } else {
      return <SplashScreen loadState={this.getLoadState()} isEmbed={isEmbed} />;
    }
  }
}


if (shouldRedirectForIE) {
  //do nothing
} else if (process.env.PREVIEW) {
    pageView("/");
    ReactDOM.render(<PreviewPage />, document.getElementById("app"));
} else if (isMobile && !isIPad && !pathInResponsiveViews()) {
    pageView("/mobile-not-supported");
    ReactDOM.render(<NotSupported />, document.getElementById("app"));
} else {
    pageView("/start");
    ReactDOM.render(<App />, document.getElementById("app"));
}